import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../services/general.service'; // Adjust the path as needed
import { Matrix } from '../../models/modelos'; // Adjust the path as needed
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resultant-matrix',
  templateUrl: './resultant-matrix.component.html'
})
export class ResultantMatrixComponent implements OnInit {
  matrix!: Matrix;

  constructor(private generalService: GeneralService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getMatrix();
  }

  getMatrix() {
    this.generalService.getResultantMatrix().subscribe({
      next: (data: Matrix) => {
        this.matrix = data;
      },
      error: (error) => {
        this.toastr.error( error.message, 'Operación fallida');
      },
    });
  }

}

<div class="d-flex justify-content-center align-items-center" style="height: calc(100vh - 56px);">
  <div class="card p-4" style="width: 300px;">
    <h2 class="mb-3 text-center">Iniciar sesión</h2>
    <form novalidate (ngSubmit)="onSubmit()" [formGroup]="formulario">
      <div>
        <label for="email">Correo:</label>
        <input formControlName="email" [class.is-invalid]="formulario.invalid && formulario.dirty" class="form-control" id="email">
        <div class="invalid-feedback">
          {{ formulario.get('email')?.errors?.['required'] ? 'El correo es requerido' : '' }}
          {{ formulario.get('email')?.errors?.['email'] ? 'El correo no es válido' : '' }}
        </div>
      </div>
      <div class="text-center">
        <button [ladda]="isLoading" type="submit" class="mt-4 btn btn-primary" [disabled]="formulario.invalid">Ingresar</button>
      </div>
    </form>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { Matrix, MatrixField, User } from '../models/modelos';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(private httpClient: HttpClient) { }

  private readonly apiUrl = environment.apiUrl;

  //funcion para enviar correo en el body al la api
  login(email: string): Observable<User> {
    return this.httpClient
      .post<User>(`${this.apiUrl}/users/login`, { email })
      .pipe(
        tap((data: User) => console.log(data)),
        catchError(this.handleError)
      );
  }

  getPendingField(matrixId: string): Observable<MatrixField> {
    return this.httpClient
      .get<MatrixField>(`${this.apiUrl}/matrixes/pending-field/${matrixId}`)
      .pipe(
        tap((data: MatrixField) => console.log(data)),
        catchError(this.handleError)
      );
  }

  updateMatrixField(matrixId: string, fieldId: string, value: null | string): Observable<MatrixField> {
    return this.httpClient
      .put<MatrixField>(`${this.apiUrl}/matrixes/update-field/${matrixId}/${fieldId}`, { value })
      .pipe(
        tap((data: MatrixField) => console.log(data)),
        catchError(this.handleError)
      );
  }

  getResultantMatrix(): Observable<Matrix> {
    return this.httpClient
      .get<Matrix>(`${this.apiUrl}/matrixes/resultant-matrix`)
      .pipe(
        tap(() => console.log('matrix obtenida')),
        catchError(this.handleError)
      );
  }

  getUsers(): Observable<User[]> {
    const params = new HttpParams()
      .set('sort', 'number')
    return this.httpClient
      .get<User[]>(`${this.apiUrl}/users`, { params })
      .pipe(
        tap((data: User[]) => console.log(data)),
        catchError(this.handleError)
      );
  }

  //funcion para capturar el error que devuelve el servidor
  private handleError(err: HttpErrorResponse) {
    console.log(err);
    return throwError(() => err.error); //devuelve el error
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VariableRatingComponent } from './components/variable-rating/variable-rating.component';
import { UserProgressComponent } from './components/user-advance/user-advance.component';
import { PagesModule } from './pages/pages.module';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ToastrModule } from 'ngx-toastr';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LaddaModule } from 'angular2-ladda';
import { ResultantMatrixComponent } from './components/resultant-matrix/resultant-matrix.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    VariableRatingComponent,
    UserProgressComponent,
    HomeComponent,
    NavBarComponent,
    ResultantMatrixComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    NgbModule,
    PagesModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true
    }),
    LoadingBarHttpClientModule,
    LaddaModule,
    NgxSpinnerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

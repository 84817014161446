
<div class="container">
    <div class="row mb-4">
        <div class="col-12">
          <h2 class="text-center">
            Progreso de registro de ponderación de variables
          </h2>
        </div>
    </div>
    <div class="card g-4 mb-3" *ngFor="let user of users">
        <div class="card-body">
            <div class="row no-gutters align-items-center">
                <div class="col-12 col-lg-3 mb-2 mb-lg-0">
                    <h5 class="card-title mb-0">{{ user.number }} - {{ user.names }}</h5>
                </div>
                <div class="col-12 col-lg-9">
                  <ngb-progressbar [showValue]="true" type="primary" [value]="user.advance" [max]="user.fieldsQty"></ngb-progressbar>
                </div>
            </div>
        </div>
    </div>
</div>

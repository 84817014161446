import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/components/login/login.component';
import { VariableRatingComponent } from './components/variable-rating/variable-rating.component';
import { UserProgressComponent } from './components/user-advance/user-advance.component';
import { HomeComponent } from './home/home.component';
import { authGuard } from './guards/auth.guard';
import { ResultantMatrixComponent } from './components/resultant-matrix/resultant-matrix.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [authGuard] },
  { path: 'home', component: HomeComponent, canActivate: [authGuard] },
  { path: 'variable-rating', component: VariableRatingComponent , canActivate: [authGuard] },
  { path: 'user-advance', component: UserProgressComponent, canActivate: [authGuard] },
  { path: 'resultant-matrix', component: ResultantMatrixComponent },
  { path:'**', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<!-- Main Content -->
<div class="d-flex flex-column justify-content-center home-container">
    <div class="container text-center">
        <!-- Paragraph -->
        <p class="lead mb-4">
          Esta herramienta ha sido creada para realizar la calificación de las variables identificadas previamente, por cada pareja de variables, se plantean las cuestiones siguientes: ¿existe una relación de influencia directa entre la variable A y la variable B? si es que no, anotamos 0, en el caso contrario, nos preguntamos si esta relación de influencia directa es, débil (1), mediana (2), fuerte (3)
        </p>
        <!-- Continue Button -->
        <button class="btn btn-primary btn-lg" (click)="continuar()">Continuar</button>
    </div>
</div>

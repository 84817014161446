<ng-container *ngIf="!isFinished; else finishedTmpl">
  <div class="container" *ngIf="field">
    <div class="row">
      <div class="col-12">
        <h2 class="text-center">
          Seleccione el nivel de influencia de la variable A sobre la variable B
        </h2>
        <p class="text-end mt-2">
          <small>Progreso: {{ field.order }}/{{ user.fieldsQty }}</small>
        </p>
      </div>
    </div>
    <div class="row g-4 mt-1">
      <div class="col-12 col-md-5 d-flex">
        <div class="card w-100">
            <h4 class="card-header text-center fw-bold">
              Variable A
            </h4>
          <div class="card-body d-flex flex-column">
            <h5 class="card-title text-center fw-bold my-3">
              {{ field.influentialVariable.number }} - {{ field.influentialVariable.name }}
              <br>
              <span class="badge bg-primary mt-1">Reto {{ field.influentialVariable.challengeNumber }}</span>
            </h5>
            <p class="card-text">
              {{ field.influentialVariable.description }}
            </p>
          </div>
        </div>

      </div>
      <div class="arrow col-12 col-md-2 img-fluid d-flex justify-content-center align-items-center">
        <svg height="120px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
          <g>
            <polygon points="512,256 317.793,114.759 317.793,211.862 211.862,211.862 211.862,300.138 317.793,300.138
      317.793,397.241 	" />
            <path d="M158.897,211.862c-9.71,0-17.655,7.945-17.655,17.655v52.966c0,9.71,7.945,17.655,17.655,17.655
      c9.71,0,17.655-7.945,17.655-17.655v-52.966C176.552,219.807,168.607,211.862,158.897,211.862" />
            <path d="M88.276,211.862c-9.71,0-17.655,7.945-17.655,17.655v52.966c0,9.71,7.945,17.655,17.655,17.655
      s17.655-7.945,17.655-17.655v-52.966C105.931,219.807,97.986,211.862,88.276,211.862" />
            <path d="M17.655,211.862C7.945,211.862,0,219.807,0,229.517v52.966c0,9.71,7.945,17.655,17.655,17.655
      s17.655-7.945,17.655-17.655v-52.966C35.31,219.807,27.366,211.862,17.655,211.862" />
          </g>
        </svg>
      </div>
      <div class="col-12 col-md-5 d-flex">
        <div class="card w-100">
          <h4 class="card-header text-center fw-bold">
            Variable B
          </h4>
          <div class="card-body d-flex flex-column">
            <h5 class="card-title text-center fw-bold my-3">
              {{ field.dependentVariable.number }} - {{ field.dependentVariable.name }}
              <br>
              <span class="badge bg-primary mt-1">Reto {{ field.dependentVariable.challengeNumber }}</span>
            </h5>
            <p class="card-text">
              {{ field.dependentVariable.description }}
            </p>
          </div>
        </div>

      </div>

    </div>
    <div class="row justify-content-center text-center mb-2 mb-sm-1 mt-4">
      <div class="col-6 col-sm-auto mb-4" *ngFor="let button of buttons">
        <div class="card btn-variables" style="max-width: 18rem" tabindex="0" (keyup.enter)="onClick(button.value)"
          role="button" (click)="onClick(button.value)" [ngClass]="{
            'border-success': button.value === field.value,
            'border-secondary': button.value !== field.value
          }">
          <div class="card-header" [ngClass]="{ 'text-bg-success': button.value === field.value }" >
            <h3>
              <span class="badge"
                  [ngClass]="{ 'text-bg-light text-success': button.value === field.value,
                                'text-bg-secondary': button.value !== field.value}">
                {{ button.value }}
              </span>
            </h3>
          </div>
          <div class="card-body card-body text-secondary">
            <p [ngClass]="{
                'text-success fw-bold': button.value === field.value
              }" class="card-text" [innerHTML]="button.label"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-sm-block pb-3 text-center">
      <button [ladda]="isLoading" class="btn btn-primary btn-lg" [disabled]="field.value === null" (click)="updateMatrixField()">
        Siguiente
      </button>
    </div>
    <div  class="d-block d-sm-none w-100 pb-2">
      <button [ladda]="isLoading" class="btn btn-primary btn-lg w-100" [disabled]="field.value === null" (click)="updateMatrixField()">
        Siguiente
      </button>
    </div>
  </div>
</ng-container>
<ng-template #finishedTmpl>
  <div class="finish-container container d-flex flex-column justify-content-center align-items-center">
    <div class="card-finish card border-success mb-3">

      <div class="card-body">
        <h5 class="card-title">Ha concluido la ponderación de las variables.</h5>
        <p class="card-text">Apreciamos su participación. Gracias por contribuir en este proceso.</p>
        <button [routerLink]="'home'" class="btn btn-primary">Volver a la página principal</button>
      </div>
    </div>
</div>
</ng-template>


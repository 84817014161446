<ngx-spinner
  bdColor="rgba(0,0,0,0.4)"
  size = "medium"
  color="#ffffff"
  type = "ball-spin-clockwise"
  [fullScreen]="true"
  ><p style="color: white">
</ngx-spinner>
<ngx-loading-bar color="#ffffff" height="4px" [fixed]="true"></ngx-loading-bar>
<app-nav-bar></app-nav-bar>
<router-outlet></router-outlet>

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/modelos';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-user-advance',
  templateUrl: './user-advance.component.html',
  styleUrls: ['./user-advance.component.scss']
})
export class UserProgressComponent implements OnInit {

  public users!: User[];

  constructor(private generalService: GeneralService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.generalService.getUsers().subscribe({
      next: (data: User[]) => {
        this.users = data;
      },
      error: (error) => {
        this.toastr.error( error.message, 'Operación fallida');
      },
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Button, MatrixField, User } from 'src/app/models/modelos';
import { GeneralService } from '../../services/general.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-variable-rating',
  templateUrl: './variable-rating.component.html',
  styleUrls: ['./variable-rating.component.scss'],
})
export class VariableRatingComponent implements OnInit {
  buttons: Array<Button> = [
    { label: ' Nula<br>influencia', value: '0' },
    { label: ' Baja<br>influencia', value: '1' },
    { label: ' Mediana<br>influencia', value: '2' },
    { label: ' Fuerte<br>influencia', value: '3' },
  ];

  field!: MatrixField;
  user!: User;
  isLoading = false;
  isFinished = false;

  constructor(
    private generalService: GeneralService,
    private authService: AuthService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    const user = this.authService.getUserFromStorage();
    if (user) {
      this.user = user;
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.getMatrixField();
  }

  getMatrixField() {
    this.generalService.getPendingField(this.user.matrix).subscribe({
      next: (data: MatrixField) => {
        this.field = data;
        this.spinner.hide();
        if ("isFinished" in data && data.isFinished === true) {
          this.isFinished = true;
        } else {
          this.field = data;
        }
      },
      error: (error) => {
        this.toastr.error( error.message, 'Operación fallida');
        this.spinner.hide();
      },
    });
  }

  onClick(value: string) {
    this.field.value = value;
  }

  updateMatrixField() {
    this.spinner.show();
    this.isLoading = true;
    this.generalService
      .updateMatrixField(this.user.matrix, this.field._id, this.field.value)
      .subscribe({
        next: (data: MatrixField) => {
          this.toastr.success('La ponderación ha sido registrada', 'Operación exitosa');
          window.scrollTo(0, 0);
          this.isLoading = false;
          this.spinner.hide();
          if ("isFinished" in data && data.isFinished === true) {
            this.isFinished = true;
          } else {
            this.field = data;
          }
        },
        error: (error) => {
          this.toastr.error(error.message, 'Operación fallida');
          this.isLoading = false;
          this.spinner.hide();
        },
      });
  }
}

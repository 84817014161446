<nav class="navbar navbar-expand-md navbar-dark bg-primary fixed-top" *ngIf="currentUser">
	<div class="container-fluid">
		<!-- <a class="navbar-brand" [routerLink]="'.'">Responsive navbar</a> -->

		<!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
		<button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">&#9776;</button>

		<!-- Step 2: Add the ngbCollapse directive to the element below. -->
		<div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
			<ul class="navbar-nav me-auto mb-2 mb-lg-0" *ngIf="currentUser.isAdmin">
				<li class="nav-item">
					<a class="nav-link" [routerLink]="'home'" (click)="isMenuCollapsed = true" routerLinkActive="active">Inicio</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="'variable-rating'" (click)="isMenuCollapsed = true" routerLinkActive="active">Ponderación de variables</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="'user-advance'" (click)="isMenuCollapsed = true" routerLinkActive="active">Avance</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="'resultant-matrix'" (click)="isMenuCollapsed = true" routerLinkActive="active">Matriz resultado</a>
				</li>
			</ul>
      <span class="navbar-text text-white ms-auto">
        {{ currentUser.names }}
      </span>
		</div>
	</div>
</nav>

import { Injectable } from '@angular/core';
import { User } from '../models/modelos';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject = new BehaviorSubject<User | null>(this.getUserFromStorage());
  currentUser$ = this.currentUserSubject.asObservable();

  setUser(user: User): void {
    sessionStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  getUserFromStorage(): User | null {
    const userStr = sessionStorage.getItem('currentUser');
    if (userStr) {
      return JSON.parse(userStr);
    } else {
      return null;
    }
  }

  removeUser(): void {
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
<div *ngIf="matrix">
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th></th>
          <th *ngFor="let row of matrix.rows; index as colIndex" class="text-center">{{colIndex + 1}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of matrix.rows; index as rowIndex">
          <th class="text-center">{{ rowIndex + 1}}</th>
          <td *ngFor="let field of row.row" class="text-center" [class.bg-warning]="field.tie && field.value !== null">{{field.value}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeneralService } from '../../../services/general.service';
import { Router } from '@angular/router';
import { User } from 'src/app/models/modelos';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  formulario!: FormGroup;
  isLoading = false;
  constructor(
    private fb: FormBuilder,
    private generalService: GeneralService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) { }
  ngOnInit() {
    this.formulario = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  onSubmit() {
    if (this.formulario.valid) {
      this.isLoading = true;
      this.generalService.login(this.formulario.value.email).subscribe({
        next: (data: User) => {
          this.authService.setUser(data);
          this.toastr.success(`${data.names}`, 'Bienvenido' );
          this.router.navigate(['/home']);
          this.isLoading = false;
        },
        error: (error) => {
          this.toastr.error(error.message,'Inicio de sesión fallido');
          this.isLoading = false;
        },
      });
    }
  }
}
